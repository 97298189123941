import { Component, OnInit, ViewChild } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { SetService } from "src/app/pages/set-panel/services/set.service";
import { ApiPostMessageService } from "src/app/_services/api-post-message.service";
import { TouchlessService } from "src/app/_services/touchless.service";
import { TimeOutPopupService } from "./_services/time-out-popup.service";

@Component({
  selector: "app-time-out-popup",
  templateUrl: "./time-out-popup.component.html",
  styleUrls: ["./time-out-popup.component.sass"],
})
export class TimeOutPopupComponent implements OnInit {
  modalRef?: BsModalRef;
  bgColor: string;
  fontColor: string;
  yesBtnColor: string;
  noBtnColor: string;
  @ViewChild("template")
  private template: HTMLTemplateElement;
  constructor(
    private readonly modalService: BsModalService,
    private readonly apiPostMessageService: ApiPostMessageService,
    private readonly touchlessService: TouchlessService,
    public readonly timeOutPopupService: TimeOutPopupService,
    public readonly setService: SetService
  ) {
    touchlessService.onAction().subscribe(this._onAction.bind(this));
  }
  private _onAction(params: any) {
    switch (params.req) {
      case "time-out": {
        if (params.action == "close") {
          this.onTimeNeed();
        }
        break;
      }
    }
  }
  get timeoutStyles() {
    return this.setService.getActualSet().timeOutService;
  }
  ngOnInit() {
    this.timeOutPopupService.openModalSubject.subscribe(
      (openModal: boolean) => {
        if (openModal) this.openModal();
      }
    );
  }
  openModal() {
    this.modalRef = this.modalService.show(this.template, {
      class: "modal-dialog-centered",
      backdrop: "static",
    });
    this.apiPostMessageService.emitAction({
      req: "time-out",
    });
    this.setStyles();
  }
  onNoTimeNeed() {
    this.modalRef.hide();
    this.touchlessService.endSession();
  }
  onTimeNeed() {
    this.modalRef.hide();
    this.timeOutPopupService.resetCount();
    this.apiPostMessageService.emitAction({
      req: "time-out",
    });
  }

  private setStyles() {
    // this.bgColor = this.setService.getActualSet().timeOutService?.bkgColor;
    // document.getElementById("modalBody").style.backgroundColor = this.bgColor;
    // this.fontColor = this.setService.getActualSet().timeOutService?.fontColor;
    // document.getElementById("modalTitle").style.color = this.fontColor;
    // document.getElementById("modalTime").style.color = this.fontColor;
    this.yesBtnColor =
      this.setService.getActualSet().timeOutService?.yesBtnColor;
    document.getElementById("yesBtn").style.backgroundColor = this.yesBtnColor;
    this.noBtnColor = this.setService.getActualSet().timeOutService?.noBtnColor;
    document.getElementById("noBtn").style.backgroundColor = this.noBtnColor;
  }
}
