import { NGXLogger } from 'ngx-logger';
import { environment } from '../../environments/environment';
export abstract class BaseClass {
  constructor(
    protected readonly logger: NGXLogger,
  ) { }
  _print(...args: any[]) {
    console.log(...args);
  }
  _log(component: string, method: string, ...args: any[]) {
    this.logger.log({
      component, method,
    }, ...args);
  }
  _warn(component: string, method: string, ...args: any[]) {
    this.logger.warn({
      component, method,
    }, ...args);
  }
  _error(component: string, method: string, ...args: any[]) {
    this.logger.error({
      component, method,
    }, ...args);
    if (!environment.production) {
      alert('An error found while :' + component + '.' + method + ' >>> check console for details <<<');
    }
  }
}
