import { QrPositionEnum } from "src/app/_enums/qr.positions.enum";
import { ShowUnlink } from "src/app/_enums/qr.unlink-show.enum";
import { ICustomerField } from "../../customer-data/interfaces/customer-field.interface";
import { Company } from "./company.model";
import { Content } from "./content.model";

export class Set {
  _id: string;
  name: string;
  company: Company;
  content: Content[];
  welcomePage: SetPage;
  header: SetHeader;
  footer: SetFooter;
  details?: SetDetails;
  config: SetConfiguration;
  cartSystem: {
    enabled: boolean;
    notificationTemplate?: string;
    checkout: {
      qrCodeOpt: {
        enabled: boolean;
        url: string;
        urlWildcard: string;
        qrGeneratorConfig: any;
      };
      pageViewConfig: {
        showCategoryProperty: [
          {
            categoryModelNamePath: string[];
          }
        ];
        backBtn: {
          color: string;
        };
        sendBtn: {
          color: string;
        };
        padding: string;
        background: {
          img: string;
          color: string;
        };
        margin: string;
      };
    };
    download: {
      pdfForcePreview: boolean;
      zipName: string;
    };
  };
  timeOutService?: {
    enabled: boolean;
    countDown: number;
    backgroundColor: string;
    fontColor: string;
    yesBtnColor: string;
    noBtnColor: string;
  };
  customerForm: {
    enabled: boolean;
    customMessage?: string;
    fields?: ICustomerField[];
    padding: string;
    margin: string;
    nextBtn: {
      backgroundColor?: string;
      textConfirm?: string;
      textUpdate?: string;
    };
    background: {
      img: string;
      color: string;
    };
    inputFontStyle?: string;
  };
  documentList?: {
    background?: {
      img?: string;
      color?: string;
    };
    pagerNavigation?: {
      color?: string;
    };
    document: {
      addCartBtn: {
        color: string;
      };
      removeCartBtn: {
        color: string;
      };
    };
    margin: string;
    padding: string;
    checkoutBtn: {
      color: string;
    };
    tab: {
      backgroundColor: string;
      color: string;
    };
  };
  cardDataScanner: {
    enabled: boolean;
    configName: string;
    skipAccessBtnEnabled: boolean;
    blockBadgeReaderHotkeys: boolean;
    restrictAccess: boolean;
    eventId: string;
    api: SetCardScannerConfig;
    parser: {
      enable: boolean;
      delimiters: string[];
      fieldsCount: number;
    };
    decrypter: {
      enabled: boolean;
      byApi?: {
        method?: string;
        endPoint?: string;
      };
    };
  };
  touchlessIntegration: {
    enabled: boolean;
    touchlessData: {
      id: string;
      apiUrl: string;
      panel: {
        url: string;
        urlWildcard: string;
        html: string;
      };
      controller: {
        url: string;
        urlWildcard: string;
        html: string;
      };
    };
    controllerQrCode: {
      show: boolean;
      position: QrPositionEnum;
    };
    unlinkQrCode: {
      show: ShowUnlink;
      position: QrPositionEnum;
    };
  };
}

export interface SetHeader {
  enabled: boolean;
  background: { img: string; color: string };
}

export interface SetFooter {
  enabled: boolean;
  columns: { content: string }[];
  background: { img: string; color: string };
}

export interface SetDetails {
  UNIQUE_DOCUMENTS_COUNT: number;
  UNIQUE_HASH: string;
}

export interface SetConfiguration {
  documentPreview: string;
  categoriesNavigation: string;
  friendlyUrl: string;
  categoriesMenuType: string;
  openExternalLinks: string;
  digitalKeyboard: "ENABLED" | "DISABLED";
  resetPanelBtn: boolean;
}

export interface SetPage {
  enabled: boolean;
  background?: { img?: string; color?: string };
  titleText: string;
  titleColor: string;
  titleFont: string;
}

export interface SetCardScannerConfig {
  method: string;
  token: string;
  endPointWildcards: any;
  endPoint: string;
  auth: CardScanerAuthConfig[];
  rateLimit: CardScannerRateLimit;
}

export interface CardScanerAuthConfig {
  _ref: string;
  user: string;
  pass?: string;
  tokenAsPass: boolean;
}

export interface CardScannerRateLimit {
  requests?: number;
  seconds?: number;
}
