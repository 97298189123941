import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ToastrService } from 'ngx-toastr';
import { QueryParamsService } from './_services/query-params.service';
import { TouchlessService } from './_services/touchless.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  title = 'cl-kiosk';

  constructor(
    private readonly swUpdate: SwUpdate,
    private readonly toastr: ToastrService,
    private readonly queryParamsService: QueryParamsService,
    private readonly touchlessService: TouchlessService,
  ) { }

  ngOnInit() {
    this.swUpdate.available.subscribe(() => {
      this.toastr.info('Tap to reload', 'New version available.', {
        enableHtml: true,
        closeButton: true,
        newestOnTop: true,
        tapToDismiss: false,
        disableTimeOut: true,
        positionClass: 'toast-bottom-right'
      }).onTap.subscribe(tap => {
        this.touchlessService.endSession();
        window.location.reload();
      });
    });
  }
}
