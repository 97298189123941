import { Injectable } from "@angular/core";
import { TimeOutPopupService } from "../_shared/time-out-popup/_services/time-out-popup.service";
import { VirtualKeyboardService } from "../_shared/virtualKeyboard/_services/virtual-keyboard.service";
import { Set } from "../pages/set-panel/model/set.model";
import { SetService } from "../pages/set-panel/services/set.service";
import { TouchlessService } from "./touchless.service";

@Injectable({
  providedIn: "root",
})
export class QueryParamsService {
  constructor(
    private readonly touchlessService: TouchlessService,
    private readonly virtualKeyboardService: VirtualKeyboardService,
    private readonly setService: SetService,
    private readonly timeOutPopupService: TimeOutPopupService
  ) {
    this._checkQueryParams();
  }

  private _checkQueryParams() {
    this.setService.actualSet.subscribe(this._checkSetConfig.bind(this));
  }

  private _checkSetConfig(set: Set) {
    if (set) {
      if (
        set.touchlessIntegration &&
        set.touchlessIntegration?.enabled === true
      ) {
        this.touchlessService.checkTouchless();
      }
      if (set.config?.digitalKeyboard === "ENABLED") {
        this.virtualKeyboardService.setState(true);
      }
      if (set.timeOutService?.enabled && set.timeOutService?.enabled === true) {
        this.timeOutPopupService.setVars(
          true,
          set?.timeOutService?.countDown || 10
        );
      }
    }
  }
}
