import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { Set } from '../pages/set-panel/model/set.model';
import { SetService } from '../pages/set-panel/services/set.service';
import { ApiPostMessageService } from './api-post-message.service';

@Injectable({
  providedIn: 'root'
})
export class TouchlessService {

  public isTouchless = false;
  public isControllerConnected = false;

  constructor(
    private readonly setService: SetService,
    private readonly apiPostMessageService: ApiPostMessageService,
    private readonly Logger: NGXLogger,
  ) { }

  checkTouchless() {
    this.setService.actualSet.subscribe(this._checkSet.bind(this));
  }

  private _checkSet(set: Set) {
    if (
      set.touchlessIntegration?.enabled
    ) {
      this.isTouchless = true;
      this.apiPostMessageService.connect();
    }
  }

  onAction(): Observable<any> {
    return this.apiPostMessageService.onActionSubject
  }

  onData() {
    return this.apiPostMessageService.onDataSubject;
  }

  endSession() {
    if (this.isTouchless) {
      this.apiPostMessageService.sendAction({
        req: 'end-session'
      });
    } else {
      window.location.reload();
    }
  }

}
