import { Injectable } from '@angular/core';
import { of, Subject } from 'rxjs';
import { SetService } from 'src/app/pages/set-panel/services/set.service';

@Injectable({
  providedIn: 'root',
})
export class GenericPopupService {
  public openModalSubject = new Subject<string>();
  public targetLinks: any = [];

  constructor(private setService: SetService) {}

  public openModalDinamic(target: any) {
    let set = this.setService.getActualSet();
    switch (set.config.openExternalLinks) {
      case 'NEW_WINDOW': {
        this._openInNewWindow(target);
        break;
      }
      case 'NEW_TAB': {
        this._openInNewTab(target);
        break;
      }
      case 'POP_UP': {
        this._openInPopUp(target);
        break;
      }
      case 'DEFAULT': {
        return;
      }
    }
  }

  _openInNewWindow(target: string) {
    this.targetLinks = document.querySelector(target);
    if(this.targetLinks !== null){
      this.targetLinks.querySelectorAll('a').forEach(externalLinks => {
        externalLinks.addEventListener('click', (event: MouseEvent) => {
          event.preventDefault();
          window.open(
            externalLinks.href,
            '',
            'width=1380,height=1080,left=150,top=100'
          )
        })
      })
    }
  }

  _openInNewTab(target: any) {
    this.targetLinks = document.querySelector(target);
    if(this.targetLinks !== null){
      this.targetLinks.querySelectorAll('a').forEach(externalLinks => {
        externalLinks.addEventListener('click', (event: MouseEvent) => {
          event.preventDefault();
          externalLinks.setAttribute('target', '_blank');
        })
      })
    }
  }

  _openInPopUp(target: any) {
    this.targetLinks = document.querySelector(target);
    if(this.targetLinks !== null){
      this.targetLinks.querySelectorAll('a').forEach(externalLinks => {
        externalLinks.addEventListener('click', (event: MouseEvent) => {
          event.preventDefault();
          this.openModalSubject.next(externalLinks.href);
        })
      })
    }
  }
}
