import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseService } from '../../../_services/base.service';
import { ApiResponse } from '../../../_type/api-response.type';
import { Set } from '../model/set.model';

@Injectable({
  providedIn: 'root',
})
export class SetService extends BaseService {
  // private readonly apiPath = _utils.genApiPath('set');
  private readonly apiPath =
    environment.baseUrl +
    (environment.usePort ? ':' + environment.port : '') +
    '/' +
    environment.apiHead +
    'set';

  protected readonly jwt_token = localStorage.getItem('JWT_TOKEN');

  private setSource = new BehaviorSubject(new Set());
  actualSet = this.setSource.asObservable();

  loadedSet: Set;
  public previewOpened = false;
  public metricFlag = 1;

  constructor(logger: NGXLogger, private readonly http: HttpClient) {
    super(logger);
  }

  setSet(set: Set) {
    this.loadedSet = set;
    this.setSource.next(set);
  }

  getSet(setId: string, withJwtToken?: boolean) {
    // GET /set/[_id]?origin=kiosk
    if (!withJwtToken)
      return this.http
        .get<ApiResponse<Set>>(this.apiPath + '/' + setId + '?origin=kiosk')
        .pipe(map((res) => res.data));
    else
      return this.http
        .get<ApiResponse<Set>>(this.apiPath + '/' + setId, {
          headers: {
            Authorization: `Bearer ${this.jwt_token}`,
          },
        })
        .pipe(map((res) => res.data));
  }

  getByUrl(friendlyUrl: string) {
    return this.http.get<ApiResponse<Set[]>>(
      this.apiPath + '/friendly-url/' + friendlyUrl + '?origin=kiosk'
    );
  }

  getActualSet() {
    return this.loadedSet;
  }

  getDocumentPreviewUrl(url: string) {
    return this.http.get<ApiResponse<string>>(url);
  }

  existsSet(setId: string) {
    // HEAD /set?_id=[_id]&origin=kiosk
    return this.http.head(this.apiPath + '?' + setId + '&origin=kiosk');
  }

  download() {
    // return this.http.get('donwload')
  }
}
